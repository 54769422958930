import { useChartStore } from "../stores/chartStore";

export function Card(props: { children: any; styles?: string }) {
  const { children, styles } = props;
  const isLoading = useChartStore((state) => state.isFirstLoading);

  return (
    <div
      className={`w-full relative min-h-32 flex flex-col justify-center items-center bg-white rounded-2xl shadow-lg p-5 gap-2 ${styles}`}
    >
      {isLoading ? <span className="loader"></span> : children}
    </div>
  );
}
export function CardTitle(props: { title: string }) {
  return (
    <h2 className="text-xl font-semibold text-black text-center w-full">
      {props.title}
    </h2>
  );
}

export function TopRightQuestion(props: { onClick?: () => void }) {
  return (
    <button className="absolute top-2 right-2" onClick={props.onClick}>
      <img src="/img/question.svg" alt="question" className="w-6 h-6" />
    </button>
  );
}
