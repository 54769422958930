import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartData } from "../stores/chartStore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const _options: any = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index'
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false, // remove border lines
      },
      ticks: {
        fontFamily: "Inter",
        color: "rgba(41, 44, 47, 0.4)",
      },
    },
    y: {
      border: {
        display: false, // remove border lines
      },
      ticks: {
        fontFamily: "Inter",
        color: "rgba(41, 44, 47, 0.4)",
        callback: function (value: number) {
          if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}k`;
          } else {
            return value;
          }
        },
      },
    },
  },
};

export function TimeChart(props: { data: ChartData[] }) {
  const testData = props.data;

  const [data, setData] = useState<any>();
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (!props.data?.[0]?.label) return;

    setData({
      labels: testData.map((d) => d.label),
      datasets: [
        {
          data: testData.map((d) => d.value),
          borderColor: "#404040",
          pointRadius: 2,
        },
      ],
    });

    const newOptions = {..._options};
    
    let max = Math.max(...testData.map((d) => d.value));
    let min = Math.min(...testData.map((d) => d.value));
    newOptions.scales.y.min = 0
    newOptions.scales.y.max = Math.floor(max + 2)
    setOptions(newOptions);
  }, [props.data]);

  return data && options && <Line options={options} data={data} height={100} />;
}
