import { MinersChart } from "./MinersChart";
import { Card, CardTitle, TopRightQuestion } from "./Card";
import { HalvingTime } from "./HalvingTime";
import { ProbabilityRadius } from "./ProbabilityRadius";
import { ChartButton } from "./ChartButton";
import { useEffect, useRef, useState } from "react";
import { TimeChart } from "./TimeChart";
import { useTranslation, useTranslationChange } from "i18nano";
import { FireworksObj } from "./FireworksObj";
import { useChartStore } from "../stores/chartStore";
import { server } from "../logic/server";

let isMakingTransition = false;
export function Dashboard(props: { setInfo: (info: string) => void }) {
  const { setInfo } = props;

  const t = useTranslation();
  const translation = useTranslationChange();

  const [dataMinedCount, setDataMinedCount] = useState(0);
  const [dataBoxesAmount, setDataBoxesAmount] = useState(0);
  const [dataActiveUsers, setDataActiveUsers] = useState(0);
  const [dataTotalUsers, setDataTotalUsers] = useState(0);
  const [dataProbability, setDataProbability] = useState(0);

  const { minedCount, boxesAmount, probability, activeUsers, totalUsers } =
    useChartStore((state) => state);

  const [newData, setNewData] = useState([false, false, false, false, false]);

  useEffect(() => {
    // get lang from query
    const locale = new URLSearchParams(window.location.search).get("lang");
    if (locale === "ru") translation.change('ru')

    server.getChartData();
    // update every 7 seconds
    const interval = setInterval(() => {
      server.getChartData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!minedCount) return;

    if (isMakingTransition) return;

    if (dataMinedCount === 0) {
      setDataMinedCount(minedCount);
      setDataBoxesAmount(boxesAmount);
      setDataActiveUsers(activeUsers);
      setDataProbability(probability);
      setDataTotalUsers(totalUsers);
      return;
    }

    isMakingTransition = true;
    let data = newData;
    for (let i = 0; i < 5; i++) {
      const randomTime = Math.floor(Math.random() * 2000);
      // set to true
      setTimeout(() => {
        data[i] = true;
        if (i === 0) setDataMinedCount(minedCount);
        if (i === 1) setDataBoxesAmount(boxesAmount);
        if (i === 2) setDataActiveUsers(activeUsers);
        if (i === 3) setDataProbability(probability);
        if (i === 4) setDataTotalUsers(totalUsers);

        setNewData([...data]);
      }, randomTime);

      setTimeout(() => {
        data[i] = false;
        setNewData([...data]);
      }, randomTime + 1000);

      setTimeout(() => {
        isMakingTransition = false;
      }, 3500);
    }
  }, [activeUsers, minedCount, boxesAmount, probability]);

  let [isFireworks, setIsFireworks] = useState(false);

  return (
    <div className="w-full h-full flex flex-col">
      {isFireworks && <FireworksObj />}
      <header className="flex flex-row items-center gap-3 w-full justify-between">
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row items-center gap-3">
            <img src="/img/logo.svg" alt="logo" className="w-12 h-12" />
            <h1 className="md:text-2xl text-xl font-medium text-black">
              <a
                className="font-black underline text-[#1521ab]"
                href="https://t.me/cubesonthewater_bot"
                target="_blank"
              >
                Cubes?
              </a>{" "}
              Hashrate? Dashboard?
            </h1>
          </div>
          <div className="flex flex-row gap-2 ml-8">
            <button
              className={`text-black font-${
                translation.lang === "en" ? "bold" : "regular"
              }`}
              onClick={() => translation.change("en")}
            >
              EN
            </button>
            <button
              className={`text-black font-${
                translation.lang === "ru" ? "bold" : "regular"
              }`}
              onClick={() => translation.change("ru")}
            >
              RU
            </button>
          </div>
        </div>
      </header>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-5 w-full">
        <div className="flex flex-col w-full gap-4 md:order-1 order-3">
          <Card styles="h-full">
            <CardTitle title={t("time-to-next-halving")} />
            <HalvingTime setIsFireworks={setIsFireworks} />
            <TopRightQuestion onClick={() => setInfo("halving-info")} />
          </Card>
          <div className="flex sm:flex-row flex-col gap-4 h-full">
            <Card styles="h-full">
              <CardTitle title={t("total-cubes-mined")} />
              <p className="sm:text-2xl text-xl font-bold text-black w-full text-center">
                <SplitWithSpaces v={dataMinedCount} newData={newData[0]} />
              </p>
            </Card>
            <Card styles="h-full">
              <CardTitle title={t("total-questions-mined")} />
              <p className="sm:text-2xl text-xl font-bold text-black w-full text-center">
                <SplitWithSpaces v={dataBoxesAmount} newData={newData[1]} />
              </p>
            </Card>
            <Card styles="h-full">
              <CardTitle title={t("total-users")} />
              <p className="sm:text-2xl text-xl font-bold text-black w-full text-center">
                <SplitWithSpaces v={dataTotalUsers} newData={newData[4]} />
              </p>
            </Card>
          </div>
        </div>
        <Card styles="md:order-2 order-2">
          <div className="md:text-xl text-lg flex flex-row w-full justify-between ">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="font-semibold text-black text-start">
                {t("current-miners-online")}
              </h2>
              {/* <img
                src="/img/question.svg"
                alt="question"
                className="w-6 h-6 cursor-pointer"
                onClick={() => setInfo("miners-info")}
              /> */}
            </div>
            <div className="flex flex-row items-center gap-1 ml-2">
              <div className="w-3 h-3 bg-[#84DB8E] rounded-full shrink-0"></div>
              <p
                className={`font-bold shrink-0 ${
                  newData ? "text-green" : "text-black"
                } transition-colors duration-1000`}
              >
                <SplitWithSpaces v={dataActiveUsers} newData={newData[3]} />
              </p>
            </div>
          </div>
          <MinersChart />
        </Card>
        <Card styles="md:order-3 order-1">
          <TopRightQuestion onClick={() => setInfo("probability-info")} />
          <h2 className="text-xl font-semibold text-black">
            {t("probability-of-mining")}
          </h2>
          <ProbabilityRadius probability={probability / 100} />
        </Card>
        <Card styles="order-4">
          <div className="md:text-xl text-lg flex flex-row w-full justify-between ">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="font-semibold text-black text-start">
                {t("block-mining-time")}
              </h2>
              <img
                src="/img/question.svg"
                alt="question"
                className="w-6 h-6 cursor-pointer"
                onClick={() => setInfo("block-mining-time-info")}
              />
            </div>
            <div className="flex flex-row items-center gap-1 ml-2">
              <div className="w-3 h-3 bg-green rounded-full shrink-0"></div>
              <p className="font-bold text-black shrink-0">4.5s</p>
            </div>
          </div>
          <TimeChart
            data={[
              {
                label: "April",
                value: 4.5,
              },
              {
                label: "May",
                value: 4.5,
              },
            ]}
          />
        </Card>
      </div>
    </div>
  );
}

function SplitWithSpaces(props: { v: number; newData: boolean }) {
  const { v, newData } = props;

  return (
    <span
      className={`font-bold shrink-0 ${
        newData ? "text-green" : "text-black"
      } transition-colors duration-1000`}
    >
      {v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
    </span>
  );
}
