import { useEffect, useState } from "react";

export function useSize() {
  let [size, setSize] = useState<{ width: number; height: number } | null>(
    null
  );

  useEffect(() => {
    let resize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    resize();
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return size;
}
