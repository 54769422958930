import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartButton } from "./ChartButton";
import { useTranslation } from "i18nano";
import { useChartStore } from "../stores/chartStore";
import { server } from "../logic/server";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const _options: any = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index'
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false, // remove border lines
      },
      ticks: {
        maxTicksLimit: 6,
        fontFamily: "Inter",
        color: "rgba(41, 44, 47, 0.4)",
      },
    },
    y: {
      border: {
        display: false, // remove border lines
      },
      ticks: {
        fontFamily: "Inter",
        color: "rgba(41, 44, 47, 0.4)",
        maxTicksLimit: 6,
        callback: function (value: number) {
          // if more 1,000 show 1k
          if (!Number.isInteger(value)) {
            return;
          }

          if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}k`;
          } else {
            return value;
          }
        },
      },
    },
  },
};

export function MinersChart() {
  const activeUsers = useChartStore((state) => state.activeUsersData);
  const testData = activeUsers;

  const [data, setData] = useState<any>();
  const [options, setOptions] = useState<any>();
  const [type, setType] = useState<"day" | "week" | "month" | "all">("day");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const t = useTranslation();

  useEffect(() => {
    server.getActiveUsers(type);
  }, [type]);

  useEffect(() => {
    if (!testData?.[0]?.label) return;

    setData({
      labels: testData.map((d) => {

        let hours = new Date(d.label).getHours();
        let minutes = new Date(d.label).getMinutes();
        
        if (type === "day") {
          return `${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        } else if (type === 'week' || type === 'month'){
          return (
            new Date(d.label).toLocaleDateString("en-US", {
              weekday: "short",
            }) +
            " " +
            `${hours < 10 ? "0" + hours : hours}:${
              minutes < 10 ? "0" + minutes : minutes
            }`
          );
        } else {
          return new Date(d.label).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
        }


      }),
      datasets: [
        {
          data: testData.map((d) => d.value),
          borderColor: "#404040",
          pointRadius: 0,
        },
      ],
    });

    const newOptions = { ..._options };

    let max = Math.max(...testData.map((d) => d.value));
    let min = Math.min(...testData.map((d) => d.value));

    newOptions.scales.y.min = Math.floor(min * 0.7);
    newOptions.scales.y.max = Math.floor(max * 1.02);
    newOptions.scales.x.ticks.maxTicksLimit = type === 'day' ? 6 : 4;

    setOptions(newOptions);
  }, [testData, type]);

  return (
    <>
      {data && options && <Line options={options} data={data} height={120} />}
      <div className="flex flex-row w-full gap-2">
        <ChartButton
          title={t("day")}
          onClick={() => {
            if (isLoading || type === "day") return;
            setType("day");
          }}
          selected={type === "day"}
        />
        <ChartButton
          title={t("week")}
          onClick={() => {
            if (isLoading || type === "week") return;
            setType("week");
          }}
          selected={type === "week"}
        />
        <ChartButton
          title={t("month")}
          onClick={() => {
            if (isLoading || type === "month") return;
            setType("month");
          }}
          selected={type === "month"}
        />
        <ChartButton
          title={t("all-time")}
          onClick={() => {
            if (isLoading || type === "all") return;
            setType("all");
          }}
          selected={type === "all"}
        />
      </div>
    </>
  );
}
