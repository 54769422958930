import Fireworks from "@fireworks-js/react";

export function FireworksObj() {
  return (
    <Fireworks
      options={{ opacity: 1 }}
      style={{
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100%",
        height: "100%",
        position: "fixed",
      }}
    />
  );
}
