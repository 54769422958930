import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TranslationProvider } from "i18nano";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const translations = {
  en: () => import("./logic/translations/en.json"),
  ru: () => import("./logic/translations/ru.json"),
};

root.render(
  <React.StrictMode>
    <TranslationProvider translations={translations} language="en">
      <Suspense>
        <App />
      </Suspense>
    </TranslationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
