import { useTranslation } from "i18nano";
import { useEffect, useState } from "react";

// halving time is 1 June 2024
let halvingTime = new Date("2024-06-01T00:00:00Z");
while (halvingTime.getTime() < new Date().getTime()) {
  halvingTime.setMonth(halvingTime.getMonth() + 1);
}

export function HalvingTime(props: {
  setIsFireworks: (isFireworks: boolean) => void;
}) {
  const { setIsFireworks } = props;

  let [estimatedTime, setEstimatedTime] = useState<Date>();
  const t = useTranslation();

  useEffect(() => {
    const func = () => {
      let time = new Date(halvingTime.getTime() - new Date().getTime());

      while (time.getTime() <= 0) {
        halvingTime.setMonth(halvingTime.getMonth() + 1);
        time = new Date(halvingTime.getTime() - new Date().getTime());

        setIsFireworks(true);
        setTimeout(() => {
          setIsFireworks(false);
        }, 30000);
      }

      setEstimatedTime(time);
    };

    func();
    const interval = setInterval(() => {
      func();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-row gap-4 w-full justify-center items-center mt-2">
      {[...Array(4)].map((unit, index) => {
        const time = estimatedTime || new Date(0);
        let value = 0;
        let name = "";
        switch (index) {
          case 0:
            value = Math.floor(time.getTime() / (1000 * 60 * 60 * 24));
            name = "Days";
            break;
          case 1:
            value = Math.floor((time.getTime() / (1000 * 60 * 60)) % 24);
            name = "Hours";
            break;
          case 2:
            value = Math.floor((time.getTime() / (1000 * 60)) % 60);
            name = "Mins";
            break;
          case 3:
            value = Math.floor((time.getTime() / 1000) % 60);
            name = "Secs";
            break;
        }

        let formattedValue = value < 10 ? `0${value}` : value;

        return (
          <>
            <div className="flex flex-col items-center" key={index}>
              <span className="text-3xl font-bold text-black">
                {formattedValue}
              </span>
              <span className="text-md font-medium text-black">{name}</span>
            </div>
            <p className="text-xl font-bold">{index < 3 && ":"}</p>
          </>
        );
      })}
    </div>
  );
}
