export function BlurPanel(props: {onClick?: () => void}) {
  return (
    <div
      onClick={props.onClick}
      className="absolute left-0 w-full h-full z-10"
      style={{
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        top: document.documentElement.scrollTop,
      }}
    />
  );
}
