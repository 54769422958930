import { useTranslation } from "i18nano";
import { useEffect, useRef, useState } from "react";
import { useSize } from "../hooks/useSize";

let levels = [0.2, 0.4, 0.6, 0.8, 1];
let levelNames = ["very-low", "low", "neutral", "high", "very-high"];

export function ProbabilityRadius(props: { probability: number }) {
  let [pointer, setPointer] = useState<number | null>(null);
  let ref = useRef<HTMLImageElement>(null);

  const size = useSize();

  const t = useTranslation();

  const calculatePointerPosition = () => {
    if (!ref.current) return;

    // get real width of the element
    let width = ref.current.clientWidth - 25;
    // calculate left position based on probability
    let left = Math.max(0, Math.min(width * props.probability, width));

    setPointer(left);
  };

  useEffect(() => {
    calculatePointerPosition();
  }, [props.probability, size]);

  return (
    <div className="flex w-full max-w-lg flex-col items-center mt-6 ">
      <div className="w-full h-max px-2 py-1.5 rounded-full relative"  style={{
        boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.1)",
      }}>
        <img
          src="/img/progress.svg"
          alt="progress"
          ref={ref}
          onLoad={calculatePointerPosition}
          className="w-full h-full"
        />
        {pointer !== null && (
          <div
            className="w-max h-max absolute top-1/2 transform -translate-y-1/2 p-1 bg-white rounded-2xl"
            style={{ left: pointer,
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
             }}
          >
            <img src="/img/pointer.svg" alt="pointer" className="w-9 h-9 object-contain" />
          </div>
        )}
      </div>
      <div className="flex flex-col w-max min-w-32 items-center mt-8 bg-[#F5F6FA] px-4 py-2 rounded-xl mb-2" style={{
        boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.1)",
      }}>
          <p className="text-2xl font-bold text-black">
            {Math.floor(props.probability * 100)}
          </p>
          <h2 className="text-lg font-medium text-black">
            {
              t(levelNames[
                levels.findIndex((level) => props.probability <= level)
              ])
            }
          </h2>
        </div>
    </div>
  );
}
