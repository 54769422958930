import { useState } from "react";
import { Dashboard } from "./components/Dashboard";
import { InfoPanel } from "./components/InfoPanel";

function App() {
  const [info, setInfo] = useState<string | null>(null);

  return (
    <main className="flex w-full h-max min-h-screen pt-4 md:px-10 px-2 pb-16 overflow-x-hidden">
      <Dashboard setInfo={setInfo} />
      {info && <InfoPanel type={info} setInfo={setInfo} />}
    </main>
  );
}

export default App;
