export function ChartButton(props: {
  title: string;
  onClick: () => void;
  selected?: boolean;
}) {
  return (
    <button
      onClick={props.onClick}
      className="bg-darkWhite text-black font-regular rounded-full px-1 py-0.5 w-full text-sm mt-2 shadow-sm"
      style={{
        boxShadow: props.selected
          ? "inset 0px 2px 0px 0px rgba(0, 0, 0, 0.2)"
          : "",
      }}
    >
      {props.title}
    </button>
  );
}
