import { create } from "zustand";

export interface ChartData {
  label: string;
  value: number;
}

interface ChartStore {
  isFirstLoading: boolean;
  minedCount: number;
  boxesAmount: number;
  activeUsers: number;
  totalUsers: number;
  probability: number;
  activeUsersData: ChartData[];
  setData: (data: any) => void;
  setActiveUsersData: (data: any) => void;
}

export const useChartStore = create<ChartStore>((set) => ({
  isFirstLoading: true,
  minedCount: 0,
  boxesAmount: 0,
  probability: 0,
  activeUsers: 0,
  totalUsers: 0,
  activeUsersData: [],
  setActiveUsersData: (data) => {
    set({
      activeUsersData: data,
    });
  },
  setData: (data) => {
    set({
      isFirstLoading: false,
      minedCount: data.minedCount,
      boxesAmount: data.boxesAmount,
      activeUsers: data.activeUsers10min,
      probability: data.probability,
      totalUsers: data.totalUsers,
    });
  },
}));
