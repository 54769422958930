import { useEffect } from "react";
import { BlurPanel } from "./BlurPanel";
import { useTranslation } from "i18nano";

export function InfoPanel(props: {
  type: string;
  setInfo: (info: string | null) => void;
}) {
  const t = useTranslation();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <>
      <BlurPanel onClick={() => props.setInfo(null)} />
      <div
        className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-96 bg-white rounded-xl px-4 py-4 pb-5 z-10 shadow-xl"
        style={{
          top: `calc(50% + ${document.documentElement.scrollTop}px)`,
        }}
      >
        <button
          className="absolute top-3 right-3"
          onClick={() => {
            props.setInfo(null);
          }}
        >
          <img src="/img/close.svg" alt="close" className="w-4 h-4" />
        </button>
        <h1 className="text-xl font-semibold text-black mt-2">
          {t(props.type + "-title")}
        </h1>
        <p className="text-lg font-regular text-black mt-2">{t(props.type)}</p>
      </div>
    </>
  );
}
